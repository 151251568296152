import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, IconButton, Tooltip } from '@mui/material'
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { capitalize, getSrno } from 'src/utils/helpers';
import { EditOutlined } from '@mui/icons-material';
import { CurrentDateFormatWithTime, STATUS_OPTIONS } from "src/utils/constants";
import TableFilter from "src/components/shared/TableFilter";
import CustomLoader from 'src/components/CustomLoader';
import SwitchBtn from 'src/components/SwitchBtn';
import DeleteButton from 'src/components/DeleteButton';
import { SearchBtn2 } from 'src/components/SearchBtn';
import { formatDate } from "src/utils/helpers";
import { getAllPromocode, promocodeStatusChange, deletePromocode } from 'src/api/promocodeServices';
import ViewPromoCode from './ViewPromoCode';


export default function PromoCodeManagement() {
    const navigate = useNavigate();
    const [searchItem, setSearchItem] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [data, setData] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [viewId, setViewId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(true)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(currentPage, pageSize, index),
            grow: 0.5
        },
        {
            name: "Promo Code",
            selector: (row) => capitalize(row.promocode),
        },

        {
            name: "Start Date",
            selector: (row) => formatDate(row?.startDateTime, CurrentDateFormatWithTime),
            sortable: true,
            sortField: 'startDateTime',
        },
        {
            name: "End Date",
            selector: (row) => formatDate(row?.endDateTime, CurrentDateFormatWithTime),
            sortable: true,
            sortField: 'endDateTime',
        },
        {
            name: "Quantity",
            selector: (row) => row?.quantity || 0,
        },
        {
            name: "Amount",
            selector: (row) => row?.amount || 0,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: "Status",
            selector: (row) => <SwitchBtn status={row.status} id={row?.id} apiUrl={promocodeStatusChange} callBack={handleCallback} rowData={row} merchantPage={true} />
        },
        {
            name: (
                <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>
            ),
            cell: (row) => (
                <div className='merchant_btn_con'>
                    <Tooltip title="Edit" placement="left">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigate(`/promo-code/update/${row.id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setModalShow(true);
                                setViewId(row.id);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <DeleteButton row={row} callBack={handleCallback} apiService={deletePromocode} msg="Are you sure want to delete this promo code?" />
                </div>
            ),
        },
    ];

    useEffect(() => {
        const body = {
            searchItem: searchItem ? searchItem : "",
            pageNumber: currentPage,
            pageSize: pageSize,
            status: status,
        };
        setLoading(true)
        getAllPromocode(body)
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data.data.result);
                    setTotalPages(res.data.data.totalRecords);
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [searchItem, currentPage, pageSize, refresh, status])


    const handleCallback = () => {
        setRefresh(!refresh)
    }



    return (
        <Card>
            <CardHeader titleTypographyProps={{ variant: "h4" }} title="Promo Code Management" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                {/* <TableFilter
                    data={STATUS_OPTIONS}
                    value={status}
                    setValue={setStatus}
                    filterName="Status"
                />
                <SearchBtn2 search={searchItem} setSearch={setSearchItem} /> */}

                <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 3, mr: 3 }}
                    onClick={() => navigate("/promo-code/add")}
                >
                    Add
                </Button>
            </Box>
            <CardContent>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPages}
                    onChangePage={handlePageChange}
                    paginationPerPage={pageSize}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                        setPageSize(currentRowsPerPage);
                        setCurrentPage(currentPage);
                    }}

                />
            </CardContent>
            {modalShow ?
                <ViewPromoCode show={modalShow} onHide={() => setModalShow(false)} id={viewId} />
                : null}
        </Card>
    )
}
