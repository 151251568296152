import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { CurrentDateFormatWithTime } from 'src/utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { tostE, tostS } from 'src/utils/Toast';
import { DateTimePicker } from '@mui/x-date-pickers';
import AddEditBtn from 'src/components/addEditBtn';
import { addPromocode, getPromocodeById, updatePromocode } from 'src/api/promocodeServices';
import { Loader } from 'src/components/CustomLoader';

export default function AddEditPromocode() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [loading, setLoading] = useState(false)

  const validationSchema = (id) => Yup.object().shape({
    promocode: Yup.string()
      .required('Promo Code is required')
      .matches(/^\S+$/, 'Please enter a valid Promo Code (no spaces)')
      .min(4, 'Promo Code must be at least 4 characters')
      .max(50, 'Promo Code must not exceed 50 characters'),
    startDateTime: id ? Yup.date()
      .required('Start Date Time is required')
      .test('before-end-date', 'Start Date cannot be after End Date', function (value) {
        return !value || !this.parent.endDateTime || value <= this.parent.endDateTime;
      }) : Yup.date().required('Start Date Time is required')
        .min(new Date(), 'Start Date cannot be in the past')
        .test('before-end-date', 'Start Date cannot be after End Date', function (value) {
          return !value || !this.parent.endDateTime || value <= this.parent.endDateTime;
        }),
    endDateTime: Yup.date()
      .required('End Date Time is required')
      .min(Yup.ref('startDateTime'), 'End Date cannot be before Start Date'),
    amount: Yup.number()
      .required('Amount is required')
      .min(1, 'Amount must be greater than 0'),
    quantity: Yup.number()
      .required('Quantity is required')
      .min(1, 'Quantity must be greater than 0'),
  });

  const formik = useFormik({
    initialValues: {
      promocode: '',
      startDateTime: null,
      endDateTime: null,
      amount: '',
      quantity: '',
    },
    validationSchema: validationSchema(id),
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        ...values,
        promocode: values.promocode?.trim(),
      };

      try {
        const response = await (id ? updatePromocode({ ...payload, id }) : addPromocode(payload))
        if (response.status == 200) {
          tostS(response.data.message);
          navigate('/promo-code');
        }
      } catch (error) {
        console.error(error);
        tostE(error.response?.data?.message || 'An error occurred');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const fetchPromocode = async () => {
      try {
        setLoading(true)
        const response = await getPromocodeById({ id });
        if (response.status === 200) {
          const { data } = response.data;
          resetForm({
            values: {
              promocode: data.promocode || '',
              startDateTime: data.startDateTime ? new Date(data.startDateTime) : null,
              endDateTime: data.endDateTime ? new Date(data.endDateTime) : null,
              amount: data.amount || '',
              quantity: data.quantity || '',
            },
          });
        }
      } catch (error) {
        console.error(error);
        tostE(error.response?.data?.message || 'Failed to fetch promo code details');
      } finally {
        setLoading(false)
      }
    };
    if (id) {
      fetchPromocode();
    }
  }, [id, resetForm]);

  return (
    <Card>
      <CardContent>
        {loading ? <Loader /> :
          <FormikProvider value={formik}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {id ? 'Edit Promo Code' : 'Add Promo Code'}
            </Typography>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={12}>
                  <TextField
                    name="promocode"
                    fullWidth
                    label="Promo Code Name"
                    {...getFieldProps('promocode')}
                    error={Boolean(touched.promocode && errors.promocode)}
                    helperText={touched.promocode && errors.promocode}
                  />
                </Grid>

                <Grid item lg={6} md={12}>
                  <FormControl fullWidth error={Boolean(touched.startDateTime && errors.startDateTime)}>
                    <DateTimePicker
                      label="Start Date Time"
                      value={values.startDateTime}
                      onChange={(date) => formik.setFieldValue('startDateTime', date)}
                      open={openStart}
                      onOpen={() => setOpenStart(true)}
                      onClose={() => setOpenStart(false)}
                      inputFormat={CurrentDateFormatWithTime}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: () => setOpenStart(true),
                          helperText: touched.startDateTime && errors.startDateTime,
                          error: Boolean(touched.startDateTime && errors.startDateTime),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={12}>
                  <FormControl fullWidth error={Boolean(touched.endDateTime && errors.endDateTime)}>
                    <DateTimePicker
                      label="End Date Time"
                      value={values.endDateTime}
                      onChange={(date) => formik.setFieldValue('endDateTime', date)}
                      open={openEnd}
                      onOpen={() => setOpenEnd(true)}
                      onClose={() => setOpenEnd(false)}
                      inputFormat={CurrentDateFormatWithTime}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: () => setOpenEnd(true),
                          helperText: touched.endDateTime && errors.endDateTime,
                          error: Boolean(touched.endDateTime && errors.endDateTime),
                        },
                      }}
                    />

                  </FormControl>
                </Grid>

                <Grid item lg={6} md={12}>
                  <TextField
                    name="amount"
                    fullWidth
                    label="Amount"
                    type="number"
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>
                <Grid item lg={6} md={12}>
                  <TextField
                    name="quantity"
                    fullWidth
                    label="Quantity"
                    type="number"
                    {...getFieldProps('quantity')}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                </Grid>
              </Grid>

              <AddEditBtn isSubmitting={isSubmitting} navigateUrl="/promo-code" />
            </Form>
          </FormikProvider>
        }
      </CardContent>
    </Card>
  );
}
