import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { EMAIL_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from "../../utils/constants";
import { MESSAGE } from "../../utils/validationMessage";
import { complexEmailRegex } from "src/utils/emailCheck";
import errorHandler from "src/utils/errorHandler";
import apiUsers from "src/api/usersService";
import { tostS } from "src/utils/Toast";
import { Loader } from "src/components/CustomLoader";
import { handleNumberInput } from "src/utils/helpers";
import { Label } from "@mui/icons-material";
function GlobalSetting() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [warning, setWarning] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [show, setShow] = useState(false);

  const settingData = async () => {
    try {
      setLoading(true)
      const response = await apiUsers.getSetting();
      const entries = Object.entries(response?.data?.data);
      setData(response.data.data);
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    settingData()
  }, [])


  const initialValues = {
    googleLink: data?.googleLink ?? "",
    fbLink: data?.fbLink ?? "",
    twitterLink: data?.twitterLink ?? "",
    merchantSupportEmail: data?.merchantSupportEmail ?? "",
    customerSupportEmail: data?.customerSupportEmail ?? "",
    merchantPaymentHold: data?.merchantPaymentHold ?? "",
    phone_otp_screen: data?.phone_otp_screen ?? "",
    email_otp_screen: data?.email_otp_screen ?? "",
  }

  const validationSchema = Yup.object().shape({
    googleLink: Yup.string().url("Google link must be a valid url").trim().max(100, "Google link must be at most 100 characters").required("Google Link is required"),
    fbLink: Yup.string().url("Facebook link must be a valid url").trim().max(100, "Facebook link must be at most 100 characters").required("Facebook Link is required"),
    twitterLink: Yup.string().url("Twitter link must be a valid url").trim().max(100, "Twitter link must be at most 100 characters").required("Twitter Link is required"),
    merchantSupportEmail: Yup.string()
      .required('Merchant support email is required')
      .trim()
      .matches(EMAIL_REGEX, "Please enter a valid merchant support email")
      .max(255),
    customerSupportEmail: Yup.string()
      .required('Customer support email is required')
      .trim()
      .matches(EMAIL_REGEX, "Please enter a valid customer support email")
      .max(255),
    merchantPaymentHold: Yup.number().required("Please enter merchant payment hold days")

  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      values.googleLink = values.googleLink?.trim()
      values.fbLink = values.fbLink?.trim()
      values.twitterLink = values.twitterLink?.trim()
      values.merchantSupportEmail = values.merchantSupportEmail?.trim()
      values.customerSupportEmail = values.customerSupportEmail?.trim()
      values.merchantPaymentHold = values.merchantPaymentHold
      apiUsers
        .updateSetting(values)
        .then((res) => {
          tostS(res.data.message, {
            toastId: 'success1',
          })
          setSubmitting(false)
          settingData()
        })
        .catch((err) => {
          errorHandler(err)
          setSubmitting(false)
        });
    },
  });

  const handleClose = () => setShow(false);
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Card>
        <CardContent>
          <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
            <Typography variant="h4" sx={{ mb: 2 }} align="start">
              Settings
            </Typography>
            {loading ? <Loader /> :
              <Form >
                <Stack spacing={3}>

                  <TextField
                    name="googleLink"
                    label="Google Link *"
                    fullWidth
                    // value={formik.values.googleLink}
                    {...getFieldProps("googleLink")}
                    error={Boolean(touched.googleLink && errors.googleLink)}
                    helperText={touched.googleLink && errors.googleLink}
                  />


                  <TextField name="fbLink" label="Facebook Link *" {...getFieldProps("fbLink")} error={Boolean(touched.fbLink && errors.fbLink)} helperText={touched.fbLink && errors.fbLink} />

                  <TextField name="twitterLink" label="Twitter Link *" {...getFieldProps("twitterLink")} error={Boolean(touched.twitterLink && errors.twitterLink)} helperText={touched.twitterLink && errors.twitterLink} />

                  <TextField
                    name="merchantSupportEmail"
                    label="Merchant Support Email *"
                    {...getFieldProps("merchantSupportEmail")}
                    error={Boolean(touched.merchantSupportEmail && errors.merchantSupportEmail)}
                    helperText={touched.merchantSupportEmail && errors.merchantSupportEmail}
                  />

                  <TextField
                    name="customerSupportEmail"
                    label="Customer Support Email *"
                    {...getFieldProps("customerSupportEmail")}
                    error={Boolean(touched.customerSupportEmail && errors.customerSupportEmail)}
                    helperText={touched.customerSupportEmail && errors.customerSupportEmail}
                  />
                  <TextField
                    name="merchantPaymentHold"
                    label="Merchant Payment Hold ( In days )  *"
                    // {...getFieldProps("merchantPaymentHold")}
                    value={formik.values.merchantPaymentHold}
                    onChange={e => handleNumberInput(e, "merchantPaymentHold", formik)}
                    error={Boolean(touched.merchantPaymentHold && errors.merchantPaymentHold)}
                    helperText={touched.merchantPaymentHold && errors.merchantPaymentHold}
                  />
                  <FormGroup>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        mb: 2,
                      }}
                    >
                      <FormControlLabel
                        label="Phone OTP Screen"
                        control={
                          <Switch
                            checked={formik.values.phone_otp_screen == 1}
                            onClick={() => {
                              setShow(true);
                              setFieldKey("phone_otp_screen")
                              setWarning("Are you sure want to change the Phone OTP screen Setting? ");
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </Paper>
                  </FormGroup>
                  <FormGroup>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        mb: 2,
                      }}
                    >
                      <FormControlLabel
                        label="Email OTP Screen"
                        control={
                          <Switch
                            checked={formik.values.email_otp_screen == 1}
                            onClick={() => {
                              setShow(true);
                              setFieldKey("email_otp_screen")
                              setWarning("Are you sure want to change the Email OTP screen Setting? ");
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </Paper>
                  </FormGroup>
                  {/* <TextField name="pageSize" label="Page Size *" type="number" {...getFieldProps("pageSize")} error={Boolean(touched.pageSize && errors.pageSize)} helperText={touched.pageSize && errors.pageSize} /> */}
                </Stack>

                <Button size="large" type="submit" variant="contained" disabled={isSubmitting} sx={{ my: 2 }}>
                  Update
                </Button>
                <Button size="large" type="submit" variant="contained" onClick={() => {
                  if (!isSubmitting)
                    navigate('/dashboard/app')
                }
                }
                  sx={{ my: 2, ml: 4 }}>
                  Cancel
                </Button>
              </Form>
            }
          </FormikProvider>
        </CardContent>
        <Dialog open={show} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
          <DialogTitle as="h2">{warning}</DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              style={{ backgroundColor: "white" }}
              onClick={() => {
                formik.setFieldValue(fieldKey, formik.values[fieldKey] == 1 ? 0 : 1)
                handleClose()
              }}
            >
              Yes
            </Button>
            <Button variant="outlined" style={{ backgroundColor: "white" }} onClick={handleClose}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
}

export default GlobalSetting;
