import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getPromocodeById } from "src/api/promocodeServices";
import { Loader } from "src/components/CustomLoader";
import { CurrentDateFormatWithTime } from "src/utils/constants";
import errorHandler from "src/utils/errorHandler";
import { capitalize, formatDate } from "src/utils/helpers";

const ViewPromoCode = ({ id, show, onHide }) => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true)


    const userData = async () => {
        try {
            setLoading(true)
            const response = await getPromocodeById({ id: id });
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        userData();
    }, []);


    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Promo Code</Typography>
                            <DialogContentText>
                                {capitalize(data?.promocode)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Start Date</Typography>
                            <DialogContentText>
                                {formatDate(data?.startDateTime, CurrentDateFormatWithTime)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">End Date</Typography>
                            <DialogContentText>
                                {formatDate(data?.endDateTime, CurrentDateFormatWithTime)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Quantity</Typography>
                            <DialogContentText>
                                {data?.quantity || 0}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Amount</Typography>
                            <DialogContentText>
                                {data?.amount || 0}{" "}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => onHide()} type="submit" style={{ backgroundColor: 'white' }} >
                                Close
                            </Button>
                        </DialogActions>
                </DialogContent>
            }
        </Dialog>
    );
};

export default ViewPromoCode;
