import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import paymentHistoryServices from 'src/api/paymentHistoryServices'
import CustomLoader from 'src/components/CustomLoader'
import DownloadExcelBtn from 'src/components/DownloadExcelBtn'
import FilterByUser from 'src/components/FilterByUser'
import SearchBtn from 'src/components/SearchBtn'
import { CurrentDateFormatWithTime } from 'src/utils/constants'
import errorHandler from 'src/utils/errorHandler'
import { formatDate, getSrno } from 'src/utils/helpers'

const PaymentHistory = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: "",

    })
    const [sort, setSort] = useState({
        sortBy: "",
        asc: ""   // acs, desc
    })
    const [userId, setUserId] = useState("")
    const [allUserList, setAllUserList] = useState([])
    const [dateRange, setDateRange] = useState([null, null]);

    const fetchCouponList = async () => {
        try {
            setLoading(true)

            payload.sortBy = sort.sortBy;
            payload.sortOrder = sort.asc;
            payload.userId = userId

            // if (dateRange[0] && dateRange[1]) {
            //     payload.startDate = moment(dateRange[0]).format("YYYY/MM/DD")
            //     payload.endDate = moment(dateRange[1]).format("YYYY/MM/DD")
            // }


            let res = await paymentHistoryServices.getAllPaymentHistory(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCouponList()
    }, [payload, sort, userId, dateRange[1]])


    const handleSort = (e, sortDirection) => {
        setSort({
            sortBy: e.sortField,
            asc: sortDirection
        })
    }


    const fetchAllUsers = async () => {
        try {
            let res = await paymentHistoryServices.getAllUser()
            let user = res?.data?.data?.filter((d) => d?.fullName != null)
            setAllUserList(user)
        } catch (error) {
            errorHandler(error)
        }
    }

    useEffect(() => {
        fetchAllUsers()
    }, [])

    // const downloadExcel = () => {
    //     let data = paginData.data?.map((d, i) => {
    //         return {
    //             "Sr.no": i + 1,
    //             "Name": d?.fullName,
    //             "Email": d?.email,
    //             "Transaction Date": formatDate(d?.transactionDate, CurrentDateFormatWithTime),
    //             "Transaction Id": d?.paymentTransactionId || "-",
    //             "Amount": d?.amount,
    //         }
    //     })
    //     const wb = XLSX.utils.book_new();
    //     const ws = XLSX.utils.json_to_sheet(data);
    //     XLSX.utils.book_append_sheet(wb, ws, "Payment History Sheet");
    //     XLSX.writeFile(wb, 'Payment History.xlsx');

    // }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index),

        },
        {
            name: "Payment History Id",
            selector: (row) => row?.id
        },
        {
            name: "Name",
            selector: (row) => row?.fullName,
            sortable: true,
            sortField: "fullName"
        },
        {
            name: "Email",
            selector: (row) => row?.email,
            sortable: true,
            sortField: "email"

        },
        {
            name: "Transaction Date",
            selector: (row) => formatDate(row?.transactionDate, CurrentDateFormatWithTime),
            sortable: true,
            sortField: "transactionDate"
        },
        {
            name: "Transaction Id",
            selector: (row) => row?.paymentTransactionId,
            sortable: true,
            sortField: "paymentTransactionId"
        },
        {
            name: "Amount",
            selector: (row) => `$${row?.amount}`,
            sortable: true,
            sortField: "amount"
        },
        {
            name: "Promocode Amount",
            selector: (row) => `${row?.promocodeAmount ? `$${row?.promocodeAmount}` : "-"}`,
            sortable: false,
            sortField: "promocodeAmount"
        },
    ]


    const getexcelData = (data) => {
        let temp = data?.map((d) => {
            return {
                "Name": d?.fullName,
                "Email": d?.email,
                "Transaction Date": formatDate(d?.transactionDate, CurrentDateFormatWithTime),
                "Transaction Id": d?.paymentTransactionId || "-",
                "Amount": d?.amount,
            }
        })
        const columnWidths = [
            { wch: 15 },
            { wch: 25 },
            { wch: 20 },
            { wch: 25 },
            { wch: 15 },
        ];

        return { eData: temp, columnWidths }
    }

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Payment History Management"
                >
                </CardHeader>
                <Grid container display={"flex"} justifyContent={"flex-end"} alignItems={"end"} gap={2} >

                    {/* <DateRangePickerComp dateRange={dateRange} setDateRange={setDateRange} /> */}
                    <FilterByUser userId={userId} setUserId={setUserId} label={"Filter User"} allUserList={allUserList} />
                    <SearchBtn payload={payload} setPayload={setPayload} label={"Search by Transaction ID"} />
                    <DownloadExcelBtn data={paginData?.data} excelData={getexcelData} sheetName={"Payment History"} />

                </Grid>
                <CardContent>
                    <DataTable
                        columns={columns}
                        data={paginData?.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData?.totalCount}
                        paginationPerPage={payload?.pageSize}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        onChangeRowsPerPage={(currentRowPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowPerPage, pageNumber: currentPage })
                        }}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}

                        sortServer
                        onSort={handleSort}

                    />

                </CardContent>
            </Card>

        </>
    )
}

export default PaymentHistory