import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import ConfirmModal from './confirmModal';
import errorHandler from 'src/utils/errorHandler';
import DeleteIcon from "@mui/icons-material/Delete";
import AxiosInterceptor from 'src/utils/AxiosInterceptor';
import { toast } from 'react-toastify';


const DeleteButton = ({ row, apiUrl=null, callBack, msg = null,apiService="" }) => {

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        try {
            setLoading(true)
            let res = await apiUrl ? AxiosInterceptor().get(`${apiUrl}/${row?.id}`) : apiService({id:row?.id})
            toast.success(res?.data?.message);
            setShow(false);
            callBack()
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Tooltip title="Delete" placement="right">
                <IconButton
                    color="primary"
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>


            {show && <ConfirmModal show={show} setShow={setShow} msg={msg ? msg : "Are you sure want to delete this merchant??"} handleSwitch={handleDelete} loading={loading} />}
        </>


    )
}

export default DeleteButton