import AxiosInterceptor from "../utils/AxiosInterceptor";

export const promocodeStatusChange = `promocode/changeStatus`

export const getAllPromocode = async (payload) => {
    return await AxiosInterceptor().post(`promocode/getAllPromocode`, payload);
};
export const addPromocode = async (payload) => {
    return await AxiosInterceptor().post(`promocode/addPromocode`, payload);
};
export const updatePromocode = async (payload) => {
    return await AxiosInterceptor().post(`promocode/updatePromocode`, payload);
};
export const getPromocodeById = async (payload) => {
    return await AxiosInterceptor().post(`promocode/getOnePromocode`, payload);
};
export const deletePromocode = async (payload) => {
    return await AxiosInterceptor().post(`promocode/deletePromocode`, payload);
};



