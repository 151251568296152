import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { addWrappedText, capitalize,  getSrno } from 'src/utils/helpers';
import { Download, EditOutlined } from '@mui/icons-material';
import { approveMerchant, changeStatus, deleteMerchant, deleteMerchantApi, getAllMerchant, merchantStatusChange, viewAgreement } from 'src/api/merchantServices';
import ViewMerchant from './ViewMerchant';
import { tostE, tostS } from 'src/utils/Toast';
import RejectMerchant from './RejectMerchant';
import { STATUS_OPTIONS, SubscripitonPlansOptions } from "src/utils/constants";
import TableFilter from "src/components/shared/TableFilter";
import CustomLoader from 'src/components/CustomLoader';
import SubscripitonModal from './SubscripitonModal';
import SwitchBtn from 'src/components/SwitchBtn';
import { toast } from 'react-toastify';
import DeleteButton from 'src/components/DeleteButton';
import { SearchBtn2 } from 'src/components/SearchBtn';
import { jsPDF } from "jspdf";

export default function MerchantManagement() {
    const navigate = useNavigate();
    const [searchItem, setSearchItem] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [data, setData] = useState();
    const [warning, setWarning] = useState("");
    const [action, setAction] = useState("");
    const [show, setShow] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [statusId, setStatusId] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [rejectShow, setRejectShow] = useState(false);
    const [viewId, setViewId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(true)

    const params = new URLSearchParams(window.location.search)
    const filter = params.get("filter")


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleClose = () => setShow(false);

    const checkCondition = (requestStatus) => {
        if (requestStatus == "0") {

        }
    }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(currentPage, pageSize, index),
            grow: 0.5
        },
        {
            name: "Merchant Id",
            selector: (row) => row?.id
        },
        {
            name: "Business Name",
            sortable: true,
            selector: (row) => capitalize(row.businessName),
        },
        {
            name: "Email",
            sortable: true,
            selector: (row) => capitalize(row.email),
        },
        {
            name: "Phone Number",
            sortable: true,
            selector: (row) => capitalize(row.phoneNumber),
        },
        {
            name: "Categories",
            selector: (row) => (
                <span title={row?.categoryIds.map(item => item?.name).join(", ")}>
                    {row?.categoryIds.map(item => item?.name).join(", ")}
                </span>
            ),
        },

        {
            name: "Payment Mode",
            sortable: true,
            selector: (row) => row?.paymentModeType ? SubscripitonPlansOptions?.filter(plan => plan?.value == row?.paymentModeType)?.[0]?.label : "-",
        },

        {
            name: "Status",
            selector: (row) => <SwitchBtn status={row.status} id={row?.id} apiUrl={merchantStatusChange} callBack={handleCallback} rowData={row} merchantPage={true} />
        },
        {
            name: (
                <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>
            ),
            cell: (row) => (
                <div className='merchant_btn_con'>
                    <Tooltip title="Edit" placement="left">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigate(`/merchant/update/${row.id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>



                    <Tooltip title="View" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setModalShow(true);
                                setViewId(row.id);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <DeleteButton row={row} callBack={handleCallback} apiUrl={deleteMerchantApi} />
                    {/* <Tooltip title="Delete" placement="right">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setShow(true);
                                setStatusId(row.id);
                                setAction("delete");
                                setWarning("Are you sure want to delete this merchant?");
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> */}
                     {row.acceptTerms == true ?
                     <Tooltip title="Download Agreement" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => handleDownloadAgreement(row.id)}
                        >
                            <Download />
                        </IconButton>
                    </Tooltip>
                    :''}
                    {row.requestStatus == 0 ?
                        <>

                            <SubscripitonModal data={row} refresh={refresh} setRefresh={setRefresh} />
                            <Tooltip title="Reject" placement="bottom">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setRejectShow(true);
                                        setViewId(row.id);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        : null}
                </div>
            ),
        },
    ];

    useEffect(() => {
        const body = {
            searchItem: searchItem ? searchItem : "",
            pageNumber: currentPage,
            pageSize: pageSize,
            status: status,
        };
        setLoading(true)
        getAllMerchant(body)
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data.data.finalResult);
                    setTotalPages(res.data.data.totalRecords);
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [searchItem, currentPage, pageSize, refresh, status])


    const handleCallback = () => {
        setRefresh(!refresh)
    }


    useEffect(() => {
        if (filter == "activeMerchants") {
            setStatus([1])
        }
    }, [filter])

    const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const options = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        return d.toLocaleString('en-US', options).replace(',', ''); // Adjust formatting as needed
    };

    const addWrappedText = (doc, text, x, y, maxWidth, lineHeight = 10, pageHeight = 295) => {
        const lines = doc.splitTextToSize(text, maxWidth);
        let currentY = y;

        lines.forEach((line) => {
            if (currentY > pageHeight - 20) {
                doc.addPage();
                currentY = 20; // Reset Y position for the new page
            }
            doc.text(line, x, currentY);
            currentY += lineHeight;
        });

        return currentY; // Return the final Y position after adding the text
    };

    const getTextHeight = (doc, text, maxWidth, lineHeight = 10) => {
        const lines = doc.splitTextToSize(text, maxWidth);
        return lines.length * lineHeight;
    };

    const handleDownloadAgreement = async (merchantId) => {
        try {
            const response = await viewAgreement(merchantId);

            if (response?.status === 200 && response?.data) {
                const { id, businessName, acceptAgreementDate, terms, sharePercentage , representName } = response?.data?.data;

                const doc = new jsPDF();
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                const margin = 10;
                const rightMargin = pageWidth - margin;

                // Add Heading
                doc.setFontSize(16);
                doc.text("Merchant Agreement", pageWidth / 2, 20, null, null, "center");

                // Add Terms and Conditions
                doc.setFontSize(12);
                const termsWithSharePercentage = terms.replace(/{sharePercentage}/g, `${sharePercentage}`);
                let currentY = 40;

                // Add terms text with proper pagination
                currentY = addWrappedText(doc, termsWithSharePercentage, margin, currentY, pageWidth - 2 * margin, 10, pageHeight);

                // Calculate space required for business details
                const businessDetailsText = 
                    `Name: ${representName}\n` +
                    `Business Name: ${businessName}\n` +
                    `Merchant ID: ${id}\n` +
                    `Accept Agreement Date: ${formatDate(acceptAgreementDate)}\n` +
                    `E-Signature: `;
                const spaceRequiredForBusinessDetails = getTextHeight(doc, businessDetailsText, pageWidth - 2 * margin, 10);

                if (currentY + spaceRequiredForBusinessDetails > pageHeight - 40) {
                    doc.addPage(); // Add a new page for business details if needed
                    currentY = 20; // Reset Y position for the new page
                }

                doc.setFontSize(11);
                const businessDetailsStartY = currentY;
                currentY = addWrappedText(doc, `                E-Signature:`, rightMargin - 80, businessDetailsStartY, 80, 0, pageHeight);
                currentY += 10;

                currentY = addWrappedText(doc, `Name: ${representName}`, rightMargin - 80, currentY, 80, 0, pageHeight);
                currentY += 10;

                currentY = addWrappedText(doc, `Business Name: ${businessName}`, rightMargin - 80, currentY, 80, 0, pageHeight);
                currentY += 10;

                currentY = addWrappedText(doc, `Merchant ID: ${id}`, rightMargin - 80, currentY, 80, 0, pageHeight);
                currentY += 10;

                currentY = addWrappedText(doc, `Accept Agreement Date and Time:         ${formatDate(acceptAgreementDate)}`, rightMargin - 80, currentY, 80, 10, pageHeight);

                // Save the PDF
                doc.save(`agreement_${merchantId}.pdf`);

            } else {
                toast.error("Failed to generate the agreement.");
            }
        } catch (error) {
            console.error("Error generating agreement:", error);
            toast.error("An error occurred while generating the agreement.");
        }
    };
    
    return (
        <Card>
            <CardHeader titleTypographyProps={{ variant: "h4" }} title="Merchant Management" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <TableFilter
                    data={STATUS_OPTIONS}
                    value={status}
                    setValue={setStatus}
                    filterName="Status"
                />
                <SearchBtn2 search={searchItem} setSearch={setSearchItem} />

                <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 3, mr: 3 }}
                    onClick={() => navigate("/merchant/add")}
                >
                    Add
                </Button>
            </Box>
            <CardContent>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPages}
                    onChangePage={handlePageChange}
                    paginationPerPage={pageSize}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                        setPageSize(currentRowsPerPage);
                        setCurrentPage(currentPage);
                    }}

                />
            </CardContent>
            {modalShow ?
                <ViewMerchant show={modalShow} onHide={() => setModalShow(false)} id={viewId} />
                : null}
            {rejectShow ?
                <RejectMerchant show={rejectShow} onHide={() => setRejectShow(false)} id={viewId} onReject={() => { setRejectShow(false); setRefresh(!refresh); }} />
                : null}

        </Card>
    )
}
